import { Message } from 'element-ui';
import Vue from 'vue';
import info from "../src/components/popup/info.vue"
import tips from "../src/components/popup/tips.vue"
import refuse from "../src/components/popup/refuse.vue"
import audit from "../src/components/popup/audit.vue"
import thj from "../src/components/popup/thj.vue"
import submit from "../src/components/popup/submit.vue"
import upimg from "../src/components/popup/upimg.vue"
import pjsq from "../src/components/popup/pjsq.vue"
import assign from "../src/components/popup/assign.vue"
import partfp from "../src/components/popup/partfp.vue"
import transfer from "../src/components/popup/transfer.vue"
import screen from "../src/components/popup/screen.vue"
// screen
let screenConstructor
let screenDom
Vue.nextTick(()=>{
    screenConstructor = Vue.extend(screen);
    screenDom = new screenConstructor().$mount();
   document.body.appendChild(screenDom.$el);
})
export default {
    //成功提示信息
    msg(msg) {
        Message({
            message: msg,
            type: 'success'
        })

    },
    //失败提示信息
    err(msg) {
        Message({
            message: msg,
            type: 'error'
        })
    },
    //基本资料弹窗
    info() {
        return new Promise((resolve, reject) => {
            let infoConstructor = Vue.extend(info);
            let infoDom = new infoConstructor().$mount();
            document.body.appendChild(infoDom.$el);
        })
    },
    //完善基本资料提示
    tips() {
        return new Promise((resolve, reject) => {
            let tipsConstructor = Vue.extend(tips);
            let tipsDom = new tipsConstructor().$mount();
            document.body.appendChild(tipsDom.$el);
        })
    },
    //通用拒绝，退单...
    refuse(options) {
        let refuseConstructor = Vue.extend(refuse);
        let tipsDom = new refuseConstructor({
            data:{...options}
        }).$mount();
        document.body.appendChild(tipsDom.$el);
        return tipsDom.init()
    },
    //系负责人订单审核
    audit(){
        let Constructor = Vue.extend(audit);
        let Dom = new Constructor().$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //维修人员配件申请
    thj(){
        let Constructor = Vue.extend(thj);
        let Dom = new Constructor().$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //维修人员维修完成提交申请
    submit(){
        let Constructor = Vue.extend(submit);
        let Dom = new Constructor().$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //维修人员配件上传图片
    upimg(){
        let Constructor = Vue.extend(upimg);
        let Dom = new Constructor().$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //维修人员配件申请
    pjsq(){
        let Constructor = Vue.extend(pjsq);
        let Dom = new Constructor().$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //维修负责人分配订单
    assign(options){
        let Constructor = Vue.extend(assign);
        let Dom = new Constructor({
            data:{...options}
        }).$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //分配配件服务商
    partfp(option){
        let Constructor = Vue.extend(partfp);
        let a = {
            a1:1,
            b2:2
        }
        let Dom = new Constructor({
            data:{examine:option}
        }).$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //维修人员转单
    transfer(){
        let Constructor = Vue.extend(transfer);
        let Dom = new Constructor().$mount();
        document.body.appendChild(Dom.$el);
        return Dom.init()
    },
    //报修区域筛选
    screen(){
        return screenDom.init()
    }
}

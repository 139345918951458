<template>
  <div class="popup_tips">
    <el-dialog width="25%" :show-close="false" :visible.sync="dialogVisible">
      <div slot="title" class="title">{{ title }}</div>
      <p class="line"></p>
      <!-- ===== -->
      <div class="center">
        <p class="subtitle"><span>{{ subTitle }}:</span></p>
        <el-input v-model="value" :placeholder="placeholder" type="textarea" rows="5"></el-input>
      </div>
      <!-- ==== -->
      <p class="line"></p>
      <div class="button">
        <div class="btn_left">
          <el-button @click="cancelBtnFn">{{ leftBtn }}</el-button>
        </div>
        <div class="btn_right">
          <el-button @click="sureBtnFn">{{ rightBtn }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false, //是否展示
      title: "取消订单", //标题
      subTitle: "取消订单原因", //副标题
      leftBtn: "取消", //左侧按钮
      rightBtn: "确定", //右侧按钮
      placeholder: "请填写取消订单原因", //提示文字
      value:"",
    };
  },
  mounted() {
    // this.dialogVisible = true;
  },
  methods: {
    // 初始化函数
    init() {
      this.dialogVisible = true;
        return new Promise((resolve, resject) => {
          this.promiseResult = { resolve, resject };
        });
    },
     // 确定函数
     sureBtnFn() {
      this.promiseResult.resolve(this.value);
      //执行销毁组件实例函数
      this.destruction();
    },
    // 取消函数
    cancelBtnFn() {
    //   this.promiseResult.resject();
      this.destruction();
    },
     // 销毁函数
     destruction() {
      this.dialogVisible = false;
      //   销毁实例
      this.$destroy(true);
      //将组件从body中移除
      this.$el.parentNode.removeChild(this.$el);
    },
    // ==============++=============
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0;
  font-size: 0.25rem;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.title {
  text-align: center;
  line-height: 0.9375rem;
}

.center {
  box-sizing: border-box;
  padding: 0.1875rem;
}
.button {
  box-sizing: border-box;
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #308846;
  background: #308846;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #308846;
}

::v-deep .el-select {
  width: 100%;
}
.btn_right ::v-deep .el-button {
  background: #308846 !important;
  color: #ffffff;
  margin-left: 0.125rem;
}
.subtitle{
    font-size: .2rem;
    padding-bottom: .125rem;
}
::v-deep .el-textarea__inner{
    border: none;
    background: #F6F6F6;
    font-size: .15rem;
}
</style>

<template>
    <div class="popup_screen">
      <el-dialog width="30%" :show-close="false" :visible.sync="dialogVisible">
        <div slot="title" class="title">{{ title }}</div>
        <p class="line"></p>
        <!-- ===== -->
        <div class="form">
          <el-cascader
              v-model="address_id"
              :options="addressOptions"
              placeholder="请选择报修区域"
              clearable
              :props="{
                value: 'id',
                label: 'name',
                children: 'child',
                emitPath: false,
              }"
            ></el-cascader>
  
        </div>
        <!-- ==== -->
        <p class="line"></p>
        <div class="button">
          <div class="btn_left">
            <el-button @click="cancelBtnFn">{{ leftBtn }}</el-button>
          </div>
          <div class="btn_right">
            <el-button @click="sureBtnFn">{{ rightBtn }}</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  // 维修负责人分配订单弹窗
  export default {
    data() {
      return {
        dialogVisible: false, //是否展示
        title: "报修区域筛选", //标题
        subTitle: "描述详情", //副标题
        leftBtn: "重置", //左侧按钮
        rightBtn: "确定", //右侧按钮
        placeholder: "请详细描述情况", //提示文字
        address_id:'',
        addressOptions: [], //报修区域列表
        baseUrl: this.$env.API_BASE_URL,
      };
    },
    watch: {
    
    },
    mounted() {
      // this.dialogVisible = true;
      this.getAdressList()
    },
    methods: {
      // 初始化函数
      init() {
        this.dialogVisible = true;
        
        return new Promise((resolve, resject) => {
          this.promiseResult = { resolve, resject };
        });
      },
      // 确定函数
      sureBtnFn() {
        this.promiseResult.resolve(this.address_id);
        //执行销毁组件实例函数
        // this.destruction();
        this.dialogVisible = false;
      },
      // 取消函数
      cancelBtnFn() {
        //   this.promiseResult.resject();
        // this.destruction();
        this.address_id = "";
        this.promiseResult.resolve('');
        this.dialogVisible = false;
      },
      // 销毁函数
      destruction() {
        this.dialogVisible = false;
        //   销毁实例
        this.$destroy(true);
        //将组件从body中移除
        this.$el.parentNode.removeChild(this.$el);
      },
      //获取报修区域
     getAdressList() {
      this.$http.post("/api/index/addresslist").then((res) => {
        if (res.code == 200) {
          this.addressOptions = res.data;
        }
      });
    },
      // ==============++=============
    },
  };
  </script>
  
  <style scoped>
  ::v-deep .el-dialog__header {
    padding: 0;
    font-size: 0.25rem;
  }
  
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  
  .title {
    text-align: center;
    line-height: 0.9375rem;
  }
  .form {
    box-sizing: border-box;
    padding: 0.1875rem;
  }
  .btn_add{
    margin-bottom: .25rem;
  }
  .flex {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .center {
    box-sizing: border-box;
    padding: 0.1875rem;
  }
  .button {
    box-sizing: border-box;
    padding: 0.125rem 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #308846;
    background: #308846;
  }
  
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #308846;
  }
  
  ::v-deep .el-select {
    width: 100%;
  }
  .btn_right ::v-deep .el-button {
    background: #308846 !important;
    color: #ffffff;
    margin-left: 0.125rem;
  }
  .btn_add{
    display: flex;
    justify-content: center;
  }
  .subtitle {
    font-size: 0.2rem;
    padding-bottom: 0.125rem;
  }
  ::v-deep .el-textarea__inner {
    border: none;
    background: #f6f6f6;
    font-size: 0.15rem;
  }
  ::v-deep .el-cascader{
    width: 100%;
  }
  </style>
  
import Vue from 'vue'
import VueRouter from 'vue-router'
import self from "../../plugins/self.js"
Vue.use(VueRouter)

const pages = [
  {
    path: '/login',
    component: (resolve) => require(['@/pages/login.vue'], resolve)
  },
  {
    path: '/404',
    component: (resolve) => require(['@/pages/404.vue'], resolve)
  },
  {
    path: '/user/login',//用户登录
    component: (resolve) => require(['@/pages/user/login.vue'], resolve)
  },
  {
    path: '/faculty/login',//系负责人登录
    component: (resolve) => require(['@/pages/faculty/login.vue'], resolve)
  },
  {
    path: '/material/login',//系负责人登录
    component: (resolve) => require(['@/pages/material/login.vue'], resolve)
  },
  {
    path: '/service/login',//维修服务商登录
    component: (resolve) => require(['@/pages/service/login.vue'], resolve)
  },
  {
    path: '/user',
    component: (resolve) => require(['@/pages/user/index.vue'], resolve),
    meta: { requiresAuth: true, role: 'user' },
    redirect:"/user/orderlist",
    children: [
     {
      path:'/user/commit',
      name:'commit',
      component: (resolve) => require(['@/pages/user/commit'], resolve),
     },
     {
      path:'/user/order',
      name:'order',
      component: (resolve) => require(['@/pages/user/order/index.vue'], resolve),
      redirect:"/user/orderlist",
      children:[
        {
          path:'/user/orderlist',
          name:'orderlist',
          component: (resolve) => require(['@/pages/user/order/orderlist.vue'], resolve),
         },
         {
          path:'/user/detail',
          name:'detail',
          component: (resolve) => require(['@/pages/user/order/detail.vue'], resolve),
         },
      ]
     },
    ]
  },
  {
    path: '/faculty',
    component: (resolve) => require(['@/pages/faculty/index.vue'], resolve),
    meta: { requiresAuth: true, role: 'faculty' },
    redirect:"/faculty/orderlist",
    children: [
     {
      path:'/faculty/orderlist',
      name:'/faculty/orderlist',
      component: (resolve) => require(['@/pages/faculty/orderlist.vue'], resolve),
     },
     {
      path:'/faculty/detail',
      name:'/faculty/detail',
      component: (resolve) => require(['@/pages/faculty/detail.vue'], resolve),
     },
    ]
  },
  {
    path: '/material',
    component: (resolve) => require(['@/pages/material/index.vue'], resolve),
    meta: { requiresAuth: true, role: 'material' },
    redirect:"/material/orderlist",
    children: [
     {
      path:'/material/orderlist',
      name:'/material/orderlist',
      component: (resolve) => require(['@/pages/material/orderlist.vue'], resolve),
     },
     {
      path:'/material/detail',
      name:'/material/detail',
      component: (resolve) => require(['@/pages/material/detail.vue'], resolve),
     },
    ]
  },
  {
    path: '/service',
    component: (resolve) => require(['@/pages/service/index.vue'], resolve),
    meta: { requiresAuth: true, role: 'service' },
    children: [
     {
      path:'/service/person',
      name:'person',
      component: (resolve) => require(['@/pages/service/person/index.vue'], resolve),
      redirect:"/service/person/orderlist",
      children: [
        {
         path:'/service/person/orderlist',
         name:'/service/person/orderlist',
         component: (resolve) => require(['@/pages/service/person/orderlist.vue'], resolve),
        },
        {
          path:'/service/person/detail',
          name:'/service/person/detail',
          component: (resolve) => require(['@/pages/service/person/detail.vue'], resolve),
         },
         {
          path:'/service/person/parts',
          name:'/service/person/parts',
          component: (resolve) => require(['@/pages/service/person/parts.vue'], resolve),
         },
       ]
     },
     {
      path:'/service/head',
      name:'head',
      component: (resolve) => require(['@/pages/service/head/index.vue'], resolve),
      redirect:"/service/head/orderlist",
      children: [
        {
         path:'/service/head/orderlist',
         name:'/service/head/orderlist',
         component: (resolve) => require(['@/pages/service/head/orderlist.vue'], resolve),
        },
        {
          path:'/service/head/detail',
          name:'/service/head/detail',
          component: (resolve) => require(['@/pages/service/head/detail.vue'], resolve),
         },
       ]
     },
    ]
  },
 
]

const router = new VueRouter({
  routes: [
    // 默认路由
    {
      path: '/',
      redirect: '/login'
    },
    // 页面路由
    ...pages,
    // 没有匹配的路由重定向到404页面
    {
      path: '*',
      redirect: '/404'
    }
  ]
})
/*
  路由拦截
  1.没有登录任何页面不可以跳转，只有登录页
  2.用户登录只能进入用户的页面
  3.管理员登录只能进入管理员的页面
*/ 
// 路由跳转前
router.beforeEach((to, from, next) => {
  // 可用于拦截导航并执行一些操作，例如验证用户身份、权限控制等。
  let name = to.path.split("/")[1];
  let isUser = !!self.getStorage('userinfo');//用户是否登录
  let isFaculty = !!self.getStorage('facultyinfo');//系负责人是否登录
  let isMaterial = !!self.getStorage('materialinfo');//材料负责人是否登录
  let isService = !!self.getStorage('serviceinfo');//维修服务商是否登录
  console.log(isUser);
  if(to.path != '/login'){
    if(isUser || to.path == "/user/login"){
      if(name == 'user'){
        next()
      }
    }else if(isFaculty || to.path == "/faculty/login"){
      if(name == 'faculty'){
        next()
      }
    }else if(isMaterial || to.path == "/material/login"){
      if(name == 'material'){
        next()
      }
    }else if(isService || to.path == "/service/login"){
      if(name == 'service'){
        next()
      }
    }else{
      next({path:'/login'})
    }
  }else{
    if(isUser){
        next({path:'/user/commit'})
    }else if(isFaculty){
      next({path:'/faculty/orderlist'})
    }else if(isMaterial){
      next({path:'/material/orderlist'})
    }else if(isService){
      if(self.getStorage('serviceinfo').is_head == 0){
        next({path:'/service/person/orderlist'})
      }else{
        next({path:'/service/head/orderlist'})
      }
      
    }else{
      next()
    }
  }
  
})
// 路由跳转后
router.afterEach((to, from) => {
  // window.scrollTo(0, 0) // 每次路由改变滚动条都回到顶部
})
export default router
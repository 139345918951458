<template>
  <div class="popup_audit">
    <el-dialog width="30%" :show-close="false" :visible.sync="dialogVisible">
      <div slot="title" class="title">{{ title }}</div>
      <p class="line"></p>
      <!-- ===== -->
      <div class="form">
        <!-- <el-select v-model="serviceuser_id" placeholder="请选择维修人员">
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
        <el-form
          ref="form"
          :model="formData"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item label="维修服务商" prop="service_id">
            <el-select
              v-model="formData.service_id"
              placeholder="请选择维修服务商"
            >
              <el-option
                v-for="item in serviceList"
                :key="item.id"
                :label="item.company_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="转单原因" prop="reason_id">
            <el-select
              v-model="formData.reason_id"
              placeholder="请选择转单原因"
            >
              <el-option
                v-for="item in reasonList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- ==== -->
      <p class="line"></p>
      <div class="button">
        <div class="btn_left">
          <el-button @click="cancelBtnFn('form')">{{ leftBtn }}</el-button>
        </div>
        <div class="btn_right">
          <el-button @click="sureBtnFn('form')">{{ rightBtn }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 维修负责人分配订单弹窗
export default {
  data() {
    return {
      dialogVisible: false, //是否展示
      title: "转单", //标题
      subTitle: "描述详情", //副标题
      leftBtn: "取消", //左侧按钮
      rightBtn: "确定", //右侧按钮
      placeholder: "请详细描述情况", //提示文字
      serviceuser_id: "",
      baseUrl: this.$env.API_BASE_URL,
      serviceList: [], //维修人员列表
      reasonList: [], //转单原因列表
      formData: {
        service_id: "", //维修服务商id
        reason_id: "", //转单原因id
      },
      rules: {
        naservice_idme: [
          { required: true, message: "请选择维修服务商", trigger: "change" },
        ],
        reason_id: [
          { required: true, message: "请选择转单原因", trigger: "change" },
        ],
      },
    };
  },
  watch: {},
  mounted() {
    // this.dialogVisible = true;
  },
  methods: {
    // 初始化函数
    init() {
      this.dialogVisible = true;
      this.getServiceList();
      this.getReasonList();
      return new Promise((resolve, resject) => {
        this.promiseResult = { resolve, resject };
      });
    },
    // 确定函数
    sureBtnFn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.promiseResult.resolve(this.formData);
          //执行销毁组件实例函数
          this.destruction();
        } else {
          return false;
        }
      });
    },
    // 取消函数
    cancelBtnFn(formName) {
      //   this.promiseResult.resject();
      this.destruction();
      this.$refs[formName].resetFields();
    },
    // 销毁函数
    destruction() {
      this.dialogVisible = false;
      //   销毁实例
      this.$destroy(true);
      //将组件从body中移除
      this.$el.parentNode.removeChild(this.$el);
    },
    //获取维修服务商
    getServiceList() {
      this.$http.post("/api/service/service_list").then((res) => {
        if (res.code == 200) {
          this.serviceList = res.data;
        }
      });
    },
    //获取转单原因
    getReasonList() {
      this.$http.post("/api/service/reason_list").then((res) => {
        if (res.code == 200) {
          this.reasonList = res.data;
        }
      });
    },

    // ==============++=============
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0;
  font-size: 0.25rem;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.title {
  text-align: center;
  line-height: 0.9375rem;
}
.form {
  box-sizing: border-box;
  padding: 0.1875rem;
}
.btn_add {
  margin-bottom: 0.25rem;
}
.flex {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center {
  box-sizing: border-box;
  padding: 0.1875rem;
}
.button {
  box-sizing: border-box;
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #308846;
  background: #308846;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #308846;
}

::v-deep .el-select {
  width: 100%;
}
.btn_right ::v-deep .el-button {
  background: #308846 !important;
  color: #ffffff;
  margin-left: 0.125rem;
}
.btn_add {
  display: flex;
  justify-content: center;
}
.subtitle {
  font-size: 0.2rem;
  padding-bottom: 0.125rem;
}
::v-deep .el-textarea__inner {
  border: none;
  background: #f6f6f6;
  font-size: 0.15rem;
}
</style>

<template>
  <div class="popup_info">
    <el-dialog width="30%" :show-close="false" :visible.sync="dialogVisible">
      <div slot="title" class="title">基本资料</div>
      <p class="line"></p>
      <!-- ===== -->
      <div class="form">
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="formData"
          label-width="80px"
        >
          <!-- 选择身份 -->
          <el-form-item label="选择身份" prop="type">
            <el-radio-group v-model="formData.type" @input="typeChange">
              <el-radio :label="'department'">系部门</el-radio>
              <el-radio :label="'student'">学生</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 系部门 -->
          <el-form-item label="系部门" prop="depart_id">
            <el-select v-model="formData.depart_id" placeholder="请选择">
              <el-option
                v-for="item in departList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 姓名 -->
          <el-form-item label="姓名" prop="realname">
            <el-input
              v-model="formData.realname"
              placeholder="请输入您的姓名"
            ></el-input>
          </el-form-item>
          <!-- 班级 -->
          <el-form-item
            v-if="formData.type == 'student'"
            label="班级"
            prop="class_name"
          >
            <el-input
              v-model="formData.class_name"
              placeholder="请输入您的班级"
            ></el-input>
          </el-form-item>
          <!-- 学生证号 -->
          <el-form-item
            v-if="formData.type == 'student'"
            label="学生证号"
            prop="student_code"
          >
            <el-input
              v-model="formData.student_code"
              placeholder="请输入您的学生证号"
            ></el-input>
          </el-form-item>
          <!-- 教师工号 -->
          <el-form-item
            v-if="formData.type == 'department'"
            label="教师工号"
            prop="student_code"
          >
            <el-input
              v-model="formData.student_code"
              placeholder="请输入您的教师工号"
            ></el-input>
          </el-form-item>
          <!-- 选择身份 -->
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="formData.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="0">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <!-- ==== -->
      <p class="line"></p>
      <div class="button">
        <div class="btn_left">
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
        <div class="btn_right">
          <el-button @click="submit('ruleForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      formData: {
        type: "student",
        depart_id: null, //系部门id
        realname: "", //姓名
        class_name: "", //班级
        student_code: "", //学生证号/教师工号
        gender: 0, //性别
      },
      rules: {
        type: [{ required: true, message: "请选择身份", trigger: "blur" }],
        depart_id: [
          { required: true, message: "请选择系部门", trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入您的姓名", trigger: "blur" },
        ],
        class_name: [
          { required: true, message: "请输入您的班级", trigger: "blur" },
        ],
        student_code: [{ required: true, message: "请输入", trigger: "blur" }],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
      },
      departList: [], //系部门列表
    };
  },
  mounted() {
    this.dialogVisible = true;
    this.getDepartList();
    this.getUserInfo();
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    //获取系部门列表
    getDepartList() {
      let that = this;
      that.$http.post("/api/user/departlist").then((res) => {
        if (res.code == 200) {
          this.departList = res.data;
        }
      });
    },
    typeChange(e) {
      this.formData.student_code = "";
    },
    //获取用户基本资料
    getUserInfo() {
      let that = this;
      that.$http.post("/api/pcindex/basic_list").then((res) => {
        if (res.code == 200) {
          that.formData = res.data;
        }
      });
    },
    //提交资料
    submit(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            ...that.formData,
            // user_id:that.$self.getStorage('userinfo').user_id
          }
          that.$http.post("/api/pcindex/basic_save",data).then((res) => {
            if (res.code == 200) {
              that.$modal.msg(res.msg);
              that.dialogVisible = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // ==============++=============
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0;
  font-size: 0.25rem;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.title {
  text-align: center;
  line-height: 0.9375rem;
}

.form {
  box-sizing: border-box;
  padding: 0.1875rem;
}
.button {
  box-sizing: border-box;
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #308846;
  background: #308846;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #308846;
}

::v-deep .el-select {
  width: 100%;
}
.btn_right ::v-deep .el-button {
  background: #308846 !important;
  color: #ffffff;
  margin-left: 0.125rem;
}
</style>

<template>
  <div class="popup_audit">
    <el-dialog width="25%" :show-close="false" :visible.sync="dialogVisible">
      <div slot="title" class="title">{{ title }}</div>
      <p class="line"></p>
      <!-- ===== -->
      <div class="center">
        <p class="subtitle flex">
          <span>是否正常使用:</span>
          <el-radio-group v-model="fromData.check_status">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </p>
        <p class="subtitle flex" v-if="fromData.check_status == 1">
          <span>是否满意:</span>
          <el-radio-group v-model="fromData.evaluate">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </p>
        <!-- <el-input v-model="value" :placeholder="placeholder" type="textarea" rows="5"></el-input> -->
        <template v-if="fromData.check_status == 2">
          <p class="subtitle">
            <span>{{ subTitle }}:</span>
          </p>
          <el-input
            v-model="fromData.check_message"
            :placeholder="placeholder"
            type="textarea"
            rows="5"
          ></el-input>
        </template>
      </div>
      <!-- ==== -->
      <p class="line"></p>
      <div class="button">
        <div class="btn_left">
          <el-button @click="cancelBtnFn">{{ leftBtn }}</el-button>
        </div>
        <div class="btn_right">
          <el-button @click="sureBtnFn">{{ rightBtn }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 系负责人审核弹窗
export default {
  data() {
    return {
      dialogVisible: false, //是否展示
      title: "订单审核", //标题
      subTitle: "描述详情", //副标题
      leftBtn: "取消", //左侧按钮
      rightBtn: "确定", //右侧按钮
      placeholder: "请详细描述情况", //提示文字
      fromData: {
        check_status: 1,
        evaluate: 1,
      },
    };
  },
  mounted() {
    // this.dialogVisible = true;
  },
  methods: {
    // 初始化函数
    init() {
      this.dialogVisible = true;
      return new Promise((resolve, resject) => {
        this.promiseResult = { resolve, resject };
      });
    },
    // 确定函数
    sureBtnFn() {
        let data = null;
        if(this.fromData.check_status == 1){
            data = {
                check_status:1,
                evaluate: this.fromData.evaluate
            }
        }
        if(this.fromData.check_status == 2){
            data = {
                check_status:2,
                check_message: this.fromData.check_message
            }
        }
      this.promiseResult.resolve(data);
      //执行销毁组件实例函数
      this.destruction();
    },
    // 取消函数
    cancelBtnFn() {
      //   this.promiseResult.resject();
      this.destruction();
    },
    // 销毁函数
    destruction() {
      this.dialogVisible = false;
      //   销毁实例
      this.$destroy(true);
      //将组件从body中移除
      this.$el.parentNode.removeChild(this.$el);
    },
    // ==============++=============
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0;
  font-size: 0.25rem;
}

::v-deep .el-dialog__body {
  padding: 0;
}

.title {
  text-align: center;
  line-height: 0.9375rem;
}

.flex {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.center {
  box-sizing: border-box;
  padding: 0.1875rem;
}
.button {
  box-sizing: border-box;
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #308846;
  background: #308846;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #308846;
}

::v-deep .el-select {
  width: 100%;
}
.btn_right ::v-deep .el-button {
  background: #308846 !important;
  color: #ffffff;
  margin-left: 0.125rem;
}
.subtitle {
  font-size: 0.2rem;
  padding-bottom: 0.125rem;
}
::v-deep .el-textarea__inner {
  border: none;
  background: #f6f6f6;
  font-size: 0.15rem;
}
</style>


import router from "../src/router/index.js"
export default {

  // 本地存储-修改
  setStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },

  // 本地存储-获取
  getStorage(key) {
    return JSON.parse(localStorage.getItem(key))
  },
  //本地存储-清除
  clearStorage() {
    localStorage.clear();
  },
  //
  delayJump(url) {
    setTimeout(() => {
      router.push(url)
    }, 1000)
  },
   //
   jump(url,query) {
      router.push({
        path:url,
        query
      })
  }
}

<template>
    <div class="popup_audit">
      <el-dialog width="40%" :show-close="false" :visible.sync="dialogVisible">
        <div slot="title" class="title">{{ title }}</div>
        <p class="line"></p>
        <!-- ===== -->
        <div class="form">
          <el-form ref="ruleForm" label-position="top" :model="formData" label-width="120px">
            <!-- 上传图片 -->
            <el-form-item label="上传图片：">
              <el-upload
                :action="baseUrl + '/index/ajax/upload'"
                list-type="picture-card"
                :file-list="imgList"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <!-- ==== -->
        <p class="line"></p>
        <div class="button">
          <div class="btn_left">
            <el-button @click="cancelBtnFn">{{ leftBtn }}</el-button>
          </div>
          <div class="btn_right">
            <el-button @click="sureBtnFn">{{ rightBtn }}</el-button>
          </div>
        </div>
      </el-dialog>
      <!-- 预览图片 -->
      <el-dialog :visible.sync="imgdialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </template>
  
  <script>
  // 系负责人审核弹窗
  export default {
    data() {
      return {
        dialogVisible: false, //是否展示
        imgdialogVisible: false,
        dialogImageUrl: "",
        title: "上传配件图片", //标题
        subTitle: "描述详情", //副标题
        leftBtn: "取消", //左侧按钮
        rightBtn: "确定", //右侧按钮
        placeholder: "请详细描述情况", //提示文字
        formData: {
            images:"",
        },
        partsList: [], //配件列表
        baseUrl: this.$env.API_BASE_URL,
        imgList: [],
      };
    },
    watch: {
      imgList(value) {
        let imgArr = value.map((item) => {
          return item.response.data.url;
        });
        this.formData.images = imgArr.join(",");
      },
    },
    mounted() {
      // this.dialogVisible = true;
    },
    methods: {
      // 初始化函数
      init() {
        this.dialogVisible = true;
        return new Promise((resolve, resject) => {
          this.promiseResult = { resolve, resject };
        });
      },
      // 确定函数
      sureBtnFn() {
        this.promiseResult.resolve(this.formData);
        //执行销毁组件实例函数
        this.destruction();
      },
      // 取消函数
      cancelBtnFn() {
        //   this.promiseResult.resject();
        this.destruction();
      },
      // 销毁函数
      destruction() {
        this.dialogVisible = false;
        //   销毁实例
        this.$destroy(true);
        //将组件从body中移除
        this.$el.parentNode.removeChild(this.$el);
      },
      handleRemove(file, fileList) {
        this.imgList = fileList;
      },
      handlePreview(file) {
        this.dialogImageUrl = file.url;
        this.imgdialogVisible = true;
      },
      handleSuccess(response, file, fileList) {
        this.imgList = fileList;
      },
      typeChange(e) {
        this.formData.infos = [{ id: null, num: null }];
      },
      // ==============++=============
    },
  };
  </script>
  
  <style scoped>
  ::v-deep .el-dialog__header {
    padding: 0;
    font-size: 0.25rem;
  }
  
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  
  .title {
    text-align: center;
    line-height: 0.9375rem;
  }
  .form {
    box-sizing: border-box;
    padding: 0.1875rem;
  }
  .btn_add {
    margin-bottom: 0.25rem;
  }
  .flex {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .center {
    box-sizing: border-box;
    padding: 0.1875rem;
  }
  .button {
    box-sizing: border-box;
    padding: 0.125rem 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #308846;
    background: #308846;
  }
  
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #308846;
  }
  
  ::v-deep .el-select {
    width: 100%;
  }
  .btn_right ::v-deep .el-button {
    background: #308846 !important;
    color: #ffffff;
    margin-left: 0.125rem;
  }
  .btn_add {
    display: flex;
    justify-content: center;
  }
  .subtitle {
    font-size: 0.2rem;
    padding-bottom: 0.125rem;
  }
  ::v-deep .el-textarea__inner {
    border: none;
    background: #f6f6f6;
    font-size: 0.15rem;
  }
  </style>
  
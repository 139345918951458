/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import Qs from 'qs'
import env from '../../env.js';
import { Message, Loading } from 'element-ui';
import router from '../router'
import self from '../../plugins/self.js'
//开始加载动画
let loading;
let loadingNum = 0 //初始化接口数量
function startLoading(e) {
    let text = 'Loading'
    loading = Loading.service({
        lock: false, //是否锁定
        text: text,
        spinner: 'el-icon-loading',
        // background: 'rgba(0, 0, 0, 0.7)'
    });
}
//结束加载动画
function endLoading() {
    // console.log('关闭')
    loading.close();
}


const httprequest = axios.create({
    baseURL: env.API_BASE_URL,
    timeout: 40000, // 请求超时时间
    headers: {
        token: "",
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
});
//获取token
const getToken = function(){
    let tokenarr = ['userinfo','facultyinfo','materialinfo','serviceinfo'];
    for(let i = 0;i < tokenarr.length;i++){
        let str = tokenarr[i]
        if(!!self.getStorage(str)){
            return self.getStorage(str)?.token
        }
    }
}

// 请求拦截器
httprequest.interceptors.request.use(
    function (config) {
        // console.log(config)
        // const token = localStorage.getItem('usertoken') ? localStorage.getItem('usertoken') : '';
        // console.log(token)
        config.headers.token = getToken()
        //    查看发送请求的接口有没有设置isLoading
        if (config.headers.isLoading !== false) {
            loadingNum++ //记录需要加载动画的接口数量
            // console.log(config)
            startLoading();//请求时的加载动画
        }
        return config;
    },
    function (error) {

        return Promise.reject(error);
    }
);

//响应拦截
httprequest.interceptors.response.use(
    function (response) {
        const res = response.data;
        if(res.code == 0 || res.code == 201){
            Message({
                message: res.msg,
                type: "error"
            });
        }
        if (response.config.headers.isLoading == false) {
            // isLoading 为false时 不做任何处理 防止 有接口成功了 将还未请求成功的接口 的加载动画 给结束掉
        } else {
            loadingNum--
            if (loadingNum == 0) {
                endLoading();//结束加载动画
            }
        }

        return res;

    },
    function (error) {

        //token过期，跳转到登录页
        if (error.response.data.code == 401) {
            // console.log( window.location.hash,"路由信息");
            // let token = localStorage.getItem('usertoken');
            //     if(!token) return
                Message({
                    message: '登录超时,请重新登录',
                    type: "error"
                });
                localStorage.clear();
                // localStorage.removeItem('usertoken');
                setTimeout(() => {
                    router.push({ path: "/login" });
                }, 1000);

            loadingNum = 0//遇到一个报错 则所有的动画都结束 
            endLoading();//结束加载动画
            return Promise.reject(401);
        }
        if (error.request) {
            // 请求超时后 操作
            if (error.request.readyState == 4 && error.request?.status == 0) {
                Message({
                    message: '请求超时',
                    type: "error"
                });
                loadingNum = 0//遇到一个报错 则所有的动画都结束 
                endLoading();//结束加载动画
                return Promise.reject(504);

            }
        }

        loadingNum = 0//遇到一个报错 则所有的动画都结束 
        endLoading();//结束加载动画

        return Promise.reject(error);
    }
);

function get(url, params) {
    return new Promise((resolve, reject) => {
        httprequest
            .get(url, { params: Qs.stringify(params) })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
// qs.stringify(data)
function post(url, data) {
    // console.log(httprequest.post())
    return new Promise((resolve, reject) => {
        httprequest
            .post(url, Qs.stringify(data))
            .then((res) => {
                // console.log(res)
                resolve(res);
            })
            .catch((err) => {
                if (err != 401 || err.request?.status == 500) {
                    Message({
                        message: '错误',
                        type: "error"
                    });
                }
              
                console.log(err,22)
                // reject(err);
            })
            .finally(() => {

            });
    });
}
export default { get, post };




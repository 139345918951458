<template>
    <div class="popup_tips">
      <el-dialog width="20%" :show-close="false" :visible.sync="dialogVisible">
        <div slot="title" class="title">温馨提示</div>
        <p class="line"></p>
        <!-- ===== -->
        <div class="center">
          <span>您现在没有权限提交报修订单，</span><br>
          <span>请先补充完基本资料再提交。</span>
        </div>
        <!-- ==== -->
        <p class="line"></p>
        <div class="button">
          <div class="btn_left">
            <el-button @click="dialogVisible = false">取消</el-button>
          </div>
          <div class="btn_right">
            <el-button @click="fillin">去填写</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,//是否展示
      };
    },
    mounted() {
      this.dialogVisible = true;
    },
    methods: {
      show() {
        this.dialogVisible = true;
      },
      fillin(){
        this.dialogVisible = false;
        this.$modal.info();
      }
      // ==============++=============
    },
  };
  </script>
  
  <style scoped>
  ::v-deep .el-dialog__header {
    padding: 0;
    font-size: 0.25rem;
  }
  
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  
  .title {
    text-align: center;
    line-height: 0.9375rem;
  }
  
  .center {
    box-sizing: border-box;
    padding: 0.1875rem;
    text-align: center;
    line-height: .4rem;
    font-size: .2rem;
  }
  .button {
    box-sizing: border-box;
    padding: 0.125rem 0.25rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: #308846;
    background: #308846;
  }
  
  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #308846;
  }
  
  ::v-deep .el-select {
    width: 100%;
  }
  .btn_right ::v-deep .el-button{
      background: #308846 !important;
      color: #FFFFFF;
      margin-left: .125rem;
  }
  </style>
  
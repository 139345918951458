import self from "./self"
import modal from "./modal"
export default {
  install(Vue) {
    // 弹窗
    Vue.prototype.$self = self
    //消息提示
     // 模态框对象
     Vue.prototype.$modal = modal
  }
}
